import React, { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { UntappdMenuItem, UntappdMenuContainer } from './untappd-types';
import MobileDisplayItemV3 from './MobileDisplayItemV3';
import Typography from '@mui/material/Typography';
import { Routes, Route, useParams } from 'react-router-dom';
import MobileCocktailItem from './MobileCocktailItem';
import { CocktailMenuItem } from './menu-types';

//Read for more about CSS Grid layouts: https://mui.com/system/grid/

const oldFashioneds1: CocktailMenuItem = { id: 1, name: 'Old Fashioned', style: 'Signature', price: 11, description: 'Ology Small Batch Bourbon, Turbincado Simple, Bitters' }
const oldFashioneds2: CocktailMenuItem = { id: 2, name: 'Root Beer Old Fashioned', style: 'Signature', price: 13, description: 'Ology Small Batch Rye, Homemade Root Beer Syrup, Black Walnut Bittlers' }
const oldFashioneds3: CocktailMenuItem = { id: 3, name: 'Repo Old Fashioned', style: 'Signature', price: 11, description: 'Ology Reposado Agave Spirit, Turbincado Simple, Bitters' }
const oldFashioneds4: CocktailMenuItem = { id: 4, name: 'Cask Old Fashioned', style: 'Signature', price: 16, description: 'Choice of Rotating Cask or Single Barrel Bourbon or Rye, Turbinado Simple, Bitters (*Ask for current offerings)' }

const BourbonRye1: CocktailMenuItem = { id: 11, name: 'Manhattan', style: 'Signature', price: 12, description: 'Ology Small Batch Rye Whiskey, Sweet Vermouth, Bitters' }
const BourbonRye2: CocktailMenuItem = { id: 12, name: 'Whiskey Sour', style: 'Signature', price: 12, description: 'Ology Small Batch Bourbon, Turbinado Simple, Lemon Juice, Egg White' }
const BourbonRye3: CocktailMenuItem = { id: 13, name: 'Irish Coffee', style: 'Signature', price: 10, description: 'Ology Small Batch Bourbon, Fresh Espresso, Turbinado Simple, Fresh Cream' }
const BourbonRye4: CocktailMenuItem = { id: 14, name: 'New York Sour', style: 'Signature', price: 11, description: 'Ology Small Batch Bourbon, Lemon Juice, Trubinado Simple, Cabernet Sauvignon Float' }

const Vodka1: CocktailMenuItem = { id: 21, name: 'Espresso Martini', style: 'Signature', price: 14, description: 'Ology Vodka, Fresh Espresso G.O.A.T. Creme Liqueur, Turbinado Simple' }
const Vodka2: CocktailMenuItem = { id: 22, name: 'Dirty Martini', style: 'Signature', price: 12, description: 'Ology Vodka, Dry Vermouth, Olive Juice' }
const Vodka3: CocktailMenuItem = { id: 23, name: 'Where\'s the Apple?!', style: 'Signature', price: 12, description: 'House-made Almond Liqueur, Cinnamon-infused Ology Small Batch Rye, Lemon Juice, Turbinado Simple, Egg Whites' }
const Vodka4: CocktailMenuItem = { id: 24, name: 'Watermelon Crush', style: 'Signature', price: 10, description: 'Ology Vodka, Watermellon Juice, Lemon Juice, Basil Simple' }
const Vodka5: CocktailMenuItem = { id: 25, name: 'Lemon Drop Martini', style: 'Signature', price: 12, description: 'Ology Vodka, Lemon Juice, Simple' }

const Gin1: CocktailMenuItem = { id: 31, name: 'Bicentennial Cocktail', style: 'Signature', price: 11, description: 'Ology Royal Lavender Gin, Lemon Juice, Champagne, Simple' }
const Gin2: CocktailMenuItem = { id: 32, name: 'Sweet Summer Child', style: 'Signature', price: 10, description: 'Ology New World Gin, Rosemary, Sage, Juniper, Lemon-Lime Oleo Saccharum' }
const Gin3: CocktailMenuItem = { id: 33, name: 'The Busy Beezzzzz', style: 'Signature', price: 12, description: 'Ology London Dry Gin, Lemon Juice, Hibiscus-Lavender Infused Honey' }
const Gin4: CocktailMenuItem = { id: 34, name: 'Chat G&T', style: 'Signature', price: 9, description: 'Ology London Dry Gin, Ology Coffee Liqueur, Tonic Water' }
const Gin5: CocktailMenuItem = { id: 35, name: 'Orthodox Punch', style: 'Signature', price: 10, description: 'Mixed Berry-infused Ology New World Gin, Brovo Pretty Sweet Vermouth, Turbinado Simple' }
const Gin6: CocktailMenuItem = { id: 36, name: 'Sage Francis', style: 'Signature', price: 12, description: 'Ology New World Gin, Rosemary, Sage, Juniper, Lemon-Lime Oleo Saccharum' }
const Gin7: CocktailMenuItem = { id: 37, name: 'Florida Man', style: 'Signature', price: 10, description: 'Ology New World Gin, Fresh-squeezed Orange Juice, Orange Oleo Saccharum, Cola Syrup, Burlesque Bitters' }
const Gin8: CocktailMenuItem = { id: 38, name: 'Gimlet', style: 'Signature', price: 12, description: 'Ology London Dry Gin, Trubinado Simple, Lime Juice' }
const Gin9: CocktailMenuItem = { id: 39, name: 'Spa Days', style: 'Signature', price: 8, description: 'Ology London Dry Gin, Spa Goggles Gose, Ginger Beer' }
const Gin10: CocktailMenuItem = { id: 40, name: 'Gin Rickey', style: 'Signature', price: 9, description: 'Ology New World Gin, Lime Juice, Simple, Soda Water' }

const Rum1: CocktailMenuItem = { id: 41, name: 'Daiquiri', style: 'Signature', price: 11, description: 'Ology Florida Rum, Lime Juice, Turbinado Simple' }
const Rum2: CocktailMenuItem = { id: 42, name: 'Cuba Libre', style: 'Signature', price: 7, description: 'Ology Florida Rum, Coke, Lime Wedge' }
const Rum3: CocktailMenuItem = { id: 43, name: 'Florida \'N Stormy', style: 'Signature', price: 7.5, description: 'Ology Florida Rum, Lime Juice, Finger Beer' }
const Rum4: CocktailMenuItem = { id: 44, name: 'Mojito', style: 'Signature', price: 10, description: 'Ology Florida Rum. Mint, Lime , Soda Water' }
const Rum5: CocktailMenuItem = { id: 45, name: 'Caipirinha', style: 'Signature', price: 12, description: 'Ology Florida Rum, Turbinado Simple, Limes, Soda Water' }
const Rum6: CocktailMenuItem = { id: 46, name: 'Riki Julius', style: 'Signature', price: 13, description: 'Ology Florida Rum, Lime Juice, Orange Oleo Saccharum, Brown Sugar Spice Simple, Bitters' }
const Rum7: CocktailMenuItem = { id: 47, name: 'Jangle Bird', style: 'Signature', price: 13, description: 'Ology Golden Rum, Cappelletti Vino Apertivo, Pineapple Juice, Lime Juice, Passionfruit Simple' }

const Agave1: CocktailMenuItem = { id: 51, name: 'Margarita', style: 'Signature', price: 8, description: 'Ology Blanco Agave Sprirt, Ology Solar Flare Satsuma Liqueur, Lime Juice, Agave Nectar - Add $1 for Reposado Agave Spirit' }
const Agave2: CocktailMenuItem = { id: 52, name: 'Paloma', style: 'Signature', price: 10, description: 'Ology Reposado Agave Spirit, Lime Juice, Grapefruit Juice, Agave Nectar, Soda Water' }

const birthdayShot: CocktailMenuItem = { id: 61, name: 'Birthday Shot', style: 'Shot', price: 5, description: 'A shot of Ology Birthday Cake Crème Liqueur' }

const mocktail1: CocktailMenuItem = { id: 71, name: 'Earth Tones', style: 'Cocktail', price: 7, description: 'Oleo Saccharum, Juiper, Rosemary, Sage, Citric and Malic Acid' }
const mocktail2: CocktailMenuItem = { id: 72, name: 'Lavender Lemonade', style: 'Cocktail', price: 5, description: 'Lavender Simple, Lemon Juice, Soda Water' }


const MobileCocktailMenu = () => {
  return (
    <main style={{ backgroundColor: 'black', overflowY: 'hidden', paddingTop: '0.5em', paddingLeft: '0em' }}>
      {/* Hero unit */}
        <Container sx={{ py: 0.0, px: 0.25, paddingTop: '0px' }} maxWidth={false}>
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0em' }} variant="h3" component="h3">Old Fashioneds</Typography>
            <MobileCocktailItem key={oldFashioneds1.id} menuItem={oldFashioneds1} ></MobileCocktailItem>
            <MobileCocktailItem key={oldFashioneds2.id} menuItem={oldFashioneds2} ></MobileCocktailItem>
            <MobileCocktailItem key={oldFashioneds3.id} menuItem={oldFashioneds3} ></MobileCocktailItem>
            <MobileCocktailItem key={oldFashioneds4.id} menuItem={oldFashioneds4} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Bourbon/Rye</Typography>
            <MobileCocktailItem key={BourbonRye1.id} menuItem={BourbonRye1} ></MobileCocktailItem>
            <MobileCocktailItem key={BourbonRye2.id} menuItem={BourbonRye2} ></MobileCocktailItem>
            <MobileCocktailItem key={BourbonRye3.id} menuItem={BourbonRye3} ></MobileCocktailItem>
            <MobileCocktailItem key={BourbonRye4.id} menuItem={BourbonRye4} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Vodka</Typography>
            <MobileCocktailItem key={Vodka1.id} menuItem={Vodka1} ></MobileCocktailItem>
            <MobileCocktailItem key={Vodka2.id} menuItem={Vodka2} ></MobileCocktailItem>
            <MobileCocktailItem key={Vodka3.id} menuItem={Vodka3} ></MobileCocktailItem>
            <MobileCocktailItem key={Vodka4.id} menuItem={Vodka4} ></MobileCocktailItem>
            <MobileCocktailItem key={Vodka5.id} menuItem={Vodka5} ></MobileCocktailItem>
          </Box>  

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Gin</Typography>
            <MobileCocktailItem key={Gin1.id} menuItem={Gin1} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin2.id} menuItem={Gin2} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin3.id} menuItem={Gin3} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin4.id} menuItem={Gin4} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin5.id} menuItem={Gin5} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin6.id} menuItem={Gin6} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin7.id} menuItem={Gin7} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin8.id} menuItem={Gin8} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin9.id} menuItem={Gin9} ></MobileCocktailItem>
            <MobileCocktailItem key={Gin10.id} menuItem={Gin10} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Rum</Typography>
            <MobileCocktailItem key={Rum1.id} menuItem={Rum1} ></MobileCocktailItem>
            <MobileCocktailItem key={Rum2.id} menuItem={Rum2} ></MobileCocktailItem>
            <MobileCocktailItem key={Rum3.id} menuItem={Rum3} ></MobileCocktailItem>
            <MobileCocktailItem key={Rum4.id} menuItem={Rum4} ></MobileCocktailItem>
            <MobileCocktailItem key={Rum5.id} menuItem={Rum5} ></MobileCocktailItem>
            <MobileCocktailItem key={Rum6.id} menuItem={Rum6} ></MobileCocktailItem>
            <MobileCocktailItem key={Rum7.id} menuItem={Rum7} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Agave Spirit</Typography>
            <MobileCocktailItem key={Agave1.id} menuItem={Agave1} ></MobileCocktailItem>
            <MobileCocktailItem key={Agave2.id} menuItem={Agave2} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Birthday</Typography>
            <MobileCocktailItem key={birthdayShot.id} menuItem={birthdayShot} ></MobileCocktailItem>
          </Box>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto', columnGap: 1, rowGap: 1, px: 0 }}>
          <Typography sx={{ color: 'white', textAlign: 'center', marginTop: '0.5em' }} variant="h3" component="h3">Zero Proof Mocktails</Typography>
            <MobileCocktailItem key={mocktail1.id} menuItem={mocktail1} ></MobileCocktailItem>
            <MobileCocktailItem key={mocktail2.id} menuItem={mocktail2} ></MobileCocktailItem>
          </Box>

        </Container>
    </main>
  );
}

export default MobileCocktailMenu;
